import React from "react"
import Layout from "../components/layout"
import Ravi from "../images/cb/ravi.png"
import Pancham from "../images/cb/pancham.png"
import Clock_Chd from "../images/cb/clock-chd.png"
import Linkedin_icon from "../images/linkedin.svg"


import {Helmet} from "react-helmet"
import Captcha from "../components/captcha"
import { navigate } from "gatsby"

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      url: "",
      host: "",
      services: [],
      // budget: 3000,
      name: "",
      email: "",
      phone: "",
      website: "",
      hearabout: "",
      about: "",
      response: "",
      code: this.generateCaptchaCode(),
      captcha: "",
      key: Date.now(),
      displayResult: false,
      displayClass: "",
      defaultCheckBoxMagento: false,
      defaultCheckBoxShopify: false,
      defaultCheckBoxWeb: false,
      defaultCheckBoxDesign: false,
      defaultCheckBoxSearch: false,
      defaultCheckBoxAd: false,
      defaultCheckBoxConversion: false,
      defaultCheckBoxCompliance: false,
      defaultCheckBoxGA4: false,
      defaultCheckBoxShopifyOpt: false,
      defaultCheckBoxOther: false,
      defaultCheckBoxEmailMarketing: false,
      defaultCheckBoxWordpress: false,
      is_processing: false,
      submit_text: "Submit",
      inputValue: "",
      fieldActive: false,


      hasServiceError: false,
      serviceErrorMessage: "",
    }
    this.changeCaptcha = this.changeCaptcha.bind(this)

    this.updateInputValue = this.updateInputValue.bind(this)
    this.activateField = this.activateField.bind(this)
    this.disableFocus = this.disableFocus.bind(this)
    this.activateField1 = this.activateField1.bind(this)
    this.disableFocus1 = this.disableFocus1.bind(this)
    this.activateField2 = this.activateField2.bind(this)
    this.disableFocus2 = this.disableFocus2.bind(this)
    this.activateField3 = this.activateField3.bind(this)
    this.disableFocus3 = this.disableFocus3.bind(this)
    this.activateField4 = this.activateField4.bind(this)
    this.disableFocus4 = this.disableFocus4.bind(this)
    this.activateField5 = this.activateField5.bind(this)
    this.disableFocus5 = this.disableFocus5.bind(this)


    this.serviceErrorDiv = React.createRef()
  }
  copyToClipboard = (event) => {
    // this.emailid.select();
    // document.execCommand('copy');
    // e.target.focus();
    
    let copyText ='letstalk@cueforgood.com' ;
    navigator.clipboard.writeText(copyText);
    this.setState({ copySuccess: 'Copied!' });
    setTimeout(() => {
      this.setState({ copySuccess: null });
    }, 2000);
    // event.target.style.display = "none"
    // document.getElementById('codeCopied').style.display = ""
  };

  // to activate the input field while typing
  activateField() {
    this.setState({
      fieldActivate: true,
    })
  }

  activateField1() {
    this.setState({
      fieldActivate1: true,
    })
  }

  activateField2() {
    this.setState({
      fieldActivate2: true,
    })
  }

  activateField3() {
    this.setState({
      fieldActivate3: true,
    })
  }

  activateField4() {
    this.setState({
      fieldActivate4: true,
    })
  }

  activateField5() {
    this.setState({
      fieldActivate5: true,
    })
  }

  // to deactivate input only if it's empty
  disableFocus(e) {
    if (e.target.value === "") {
      this.setState({
        fieldActivate: false,
      })
    }
  }

  disableFocus1(e) {
    if (e.target.value === "") {
      this.setState({
        fieldActivate1: false,
      })
    }
  }

  disableFocus2(e) {
    if (e.target.value === "") {
      this.setState({
        fieldActivate2: false,
      })
    }
  }

  disableFocus3(e) {
    if (e.target.value === "") {
      this.setState({
        fieldActivate3: false,
      })
    }
  }

  disableFocus4(e) {
    if (e.target.value === "") {
      this.setState({
        fieldActivate4: false,
      })
    }
  }

  disableFocus5(e) {
    if (e.target.value === "") {
      this.setState({
        fieldActivate5: false,
      })
    }
  }

  // to update the changes in the input and activate it
  updateInputValue(e) {
    this.setState({
      inputValue: e.target.value,
    })
    this.activateField(e)
    e.preventDefault()
  }

  generateCaptchaCode() {
    let charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*"
    let lengthOtp = 6
    let captcha = []
    for (let i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      let index = Math.floor(Math.random() * charsArray.length + 1) //get the next character from the array
      if (captcha.indexOf(charsArray[index]) === -1)
        captcha.push(charsArray[index])
      else i--
    }

    return captcha.join("")
  }

  componentDidMount() {
    this.setState({
      url: document.location.href,
      host: document.location.host,
    })
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    if (name === "website") {
      let urlregex = new RegExp("^(?:https?:\\/\\/)?(?!www | www\\.)[A-Za-z0-9_-]+\\.+[A-Za-z0-9.\\/%&=\\?_:;-]+$")
      if (value.trim().length > 0) {
        if (!urlregex.test(value.trim())) {
          target.setCustomValidity("Please enter a valid domain name. example.com")
        } else {
          target.setCustomValidity("")
        }
      } else {
        target.setCustomValidity("")
      }
    }

    if (name === "phone") {
      let phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$/g
      if (value.trim().length > 0) {
        if (value.trim().length < 10) {
          target.setCustomValidity("Please enter a valid phone number with country code.")
        } else {
          if (!phoneRegex.test(value.trim())) {
            target.setCustomValidity("Please enter a valid phone number with country code.")
          } else {
            target.setCustomValidity("")
          }
        }
      } else {
        target.setCustomValidity("")
      }
    }
    if (name === "email") {
      if (!this.validateEmail(value.trim().toLowerCase())) {
        target.setCustomValidity("Please enter a valid email. email@domain.com")
      } else {
        target.setCustomValidity("")
      }
    }
    if (name !== "services") {
      this.setState({
        [name]: value,
      })
    }
  }

  handleCheckbox = event => {
    if (event.target.checked) {
      this.state.services.push(event.target.id)
      if (event.target.id === "Magento") {
        this.setState({
          defaultCheckBoxMagento: true,
        })
      } else if (event.target.id === "Shopify") {
        this.setState({
          defaultCheckBoxShopify: true,
        })
      } else if (event.target.id === "WebDevelopment") {
        this.setState({
          defaultCheckBoxWeb: true,
        })
      } else if (event.target.id === "CreativeDesign") {
        this.setState({
          defaultCheckBoxDesign: true,
        })
      } else if (event.target.id === "OrganicSearch") {
        this.setState({
          defaultCheckBoxSearch: true,
        })
      } else if (event.target.id === "DigitalAdvertising") {
        this.setState({
          defaultCheckBoxAd: true,
        })
      } else if (event.target.id === "Wordpress") {
        this.setState({
          defaultCheckBoxWordpress: true,
        })
      } else if (event.target.id === "EmailMarketing") {
        this.setState({
          defaultCheckBoxEmailMarketing: true,
        })
      } else if (event.target.id === "Other") {
        this.setState({
          defaultCheckBoxOther: true,
        })
      } else if (event.target.id === "Conversion") {
        this.setState({
          defaultCheckBoxConversion: true,
        })
      } else if (event.target.id === 'ADA/WCAGCompliance') {
        this.setState({
          defaultCheckBoxCompliance: true,
        })
      } else if (event.target.id === 'GoogleAnalytics4(GA4)Transition') {
        this.setState({
          defaultCheckBoxGA4: true,
        })
      } else if (event.target.id === 'ShopifySpeedOptimization') {
        this.setState({
          defaultCheckBoxShopifyOpt: true,
        })
      }
    } else {
      for (let i = this.state.services.length - 1; i >= 0; i--) {
        if (this.state.services[i] === event.target.id) {

          if (event.target.id === "Magento") {
            this.setState({
              defaultCheckBoxMagento: false,
            })
          } else if (event.target.id === "Shopify") {
            this.setState({
              defaultCheckBoxShopify: false,
            })
          } else if (event.target.id === "WebDevelopment") {
            this.setState({
              defaultCheckBoxWeb: false,
            })
          } else if (event.target.id === "CreativeDesign") {
            this.setState({
              defaultCheckBoxDesign: false,
            })
          } else if (event.target.id === "OrganicSearch") {
            this.setState({
              defaultCheckBoxSearch: false,
            })
          } else if (event.target.id === "DigitalAdvertising") {
            this.setState({
              defaultCheckBoxAd: false,
            })
          } else if (event.target.id === "Wordpress") {
            this.setState({
              defaultCheckBoxWordpress: false,
            })
          } else if (event.target.id === "EmailMarketing") {
            this.setState({
              defaultCheckBoxEmailMarketing: false,
            })
          } else if (event.target.id === "Other") {
            this.setState({
              defaultCheckBoxOther: false,
            })
          } else if (event.target.id === "Conversion") {
            this.setState({
              defaultCheckBoxConversion: false,
            })
          } else if (event.target.id === 'ADA/WCAGCompliance') {
            this.setState({
              defaultCheckBoxCompliance: false,
            })
          } else if (event.target.id === 'GoogleAnalytics4(GA4)Transition') {
            this.setState({
              defaultCheckBoxGA4: false,
            })
          } else if (event.target.id === 'ShopifySpeedOptimization') {
            this.setState({
              defaultCheckBoxShopifyOpt: false,
            })
          }
          this.state.services.splice(i, 1)
        }
      }
    }

    this.setState({
      services: this.state.services,
    })
  }

  changeCaptcha() {
    this.setState({
      code: this.generateCaptchaCode(),
      key: Date.now(),
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    const formData = new FormData()

    //check name
    if (this.state.name === "") {
      this.setState({
        nameErrorMessage: "Please enter name",
        hasNameError: true,
      })
      this.scrollTo(450)
      return
    } else {
      this.setState({
        hasNameError: false,
      })
    }

    //check email
    if (this.state.email.trim() === "") {
      this.setState({
        nameEmailMessage: "Please enter an valid email",
        hasEmailError: true,
      })
      this.scrollTo(400)
      return
    } else {
      this.setState({
        hasEmailError: false,
      })
    }

    //check services
    if (this.state.services.length == 0) {
      this.setState({
        serviceErrorMessage: "Please select atleast one service",
        hasServiceError: true,
      })
      this.scrollTo()
      return
    } else {
      this.setState({
        hasServiceError: false,
      })
    }

    //check about
    if (this.state.about === "") {
      this.setState({
        aboutErrorMessage: "Please enter detail about your requirements",
        hasAboutError: true,
      })
      this.scrollTo()
      return
    } else {
      this.setState({
        hasAboutError: false,
      })
    }

    //check captcha
    //check if captcha is empty
    if (this.state.captcha === "") {
      this.setState({
        captchaErrorMessage: "Captcha is empty",
        hasCaptchaError: true,
      })
      return
    } else {
      this.setState({
        hasCaptchaError: false,
      })
    }

    //check if captcha is matched
    if (this.state.captcha !== this.state.code) {
      this.setState({
        captchaErrorMessage: "Captcha not matched",
        hasCaptchaError: true,
      })
      return
    } else {
      this.setState({
        hasCaptchaError: false,
      })
    }
    let AUTO_RESPONDER_CONTACT_FORM
    let HUBSPOT_CONTACT_FORM
    if (this.state.host === "cb-redesign.netlify.com" || this.state.host === "cb-redesign.netlify.app") {
      AUTO_RESPONDER_CONTACT_FORM = "https://apistaging.cueforgood.com/"
      HUBSPOT_CONTACT_FORM = "https://api.hsforms.com/submissions/v3/integration/submit/6881310/78c7882f-e577-4fcf-80ff-e451b4a1b6bf"
    } else if (this.state.host === "localhost:8000") {
      AUTO_RESPONDER_CONTACT_FORM = "http://localhost:8001/"
      HUBSPOT_CONTACT_FORM = "https://api.hsforms.com/submissions/v3/integration/submit/6881310/78c7882f-e577-4fcf-80ff-e451b4a1b6bf"
    } else if (this.state.host === "www.cueforgood.com" || this.state.host === "cueforgood.com") {
      AUTO_RESPONDER_CONTACT_FORM = "https://apilive.cueforgood.com/"
      HUBSPOT_CONTACT_FORM = "https://api.hsforms.com/submissions/v3/integration/submit/6881310/17ba0bc5-cdb9-414d-8b21-c27686b8e77f"
    } else {
      AUTO_RESPONDER_CONTACT_FORM = "https://apistaging.cueforgood.com/"
      HUBSPOT_CONTACT_FORM = "https://api.hsforms.com/submissions/v3/integration/submit/6881310/78c7882f-e577-4fcf-80ff-e451b4a1b6bf"
    }
    let data = {
      "submittedAt": new Date().getTime(),
      "fields": [
        {
          "name": "email",
          "value": this.state.email,
        },
        {
          "name": "firstname",
          "value": this.state.name,
        },
        {
          "name": "phone",
          "value": this.state.phone,
        },
        {
          "name": "website",
          "value": this.state.website,
        },
        {
          "name": "hearabout",
          "value": this.state.hearabout,
        },
        // {
        //     "name": "budget",
        //     "value": this.state.budget
        // },
        {
          "name": "services",
          "value": this.state.services + "",
        },
        {
          "name": "about",
          "value": this.state.about,
        },
      ],

      "legalConsentOptions": { // Include this object when GDPR options are enabled
        "consent": {
          "consentToProcess": true,
          "text": "I agree to allow Example Company to store and process my personal data.",
          "communications": [
            {
              "value": true,
              "subscriptionTypeId": 999,
              "text": "I agree to receive marketing communications from Example Company.",
            },
          ],
        },
      },
    }

    const final_data = JSON.stringify(data)
    formData.append("email", this.state.email)
    formData.append("name", this.state.name)
    let _message = ""
    let _this = this
    this.setState({
      is_processing: true,
      submit_text: "Submitting...",
    })
    fetch(HUBSPOT_CONTACT_FORM, {
        method: "post",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: final_data,
      },
    ).then(response => response.json())
    .then((response) => {

      if(response.inlineMessage === "Thanks for submitting the form."){
        _this.setState({
          services: [],
          name: "",
          email: "",
          phone: "",
          website: "",
          hearabout: "",
          about: "",
          captcha: "",
          defaultCheckBoxMagento: false,
          defaultCheckBoxShopify: false,
          defaultCheckBoxWeb: false,
          defaultCheckBoxDesign: false,
          defaultCheckBoxSearch: false,
          defaultCheckBoxAd: false,
          defaultCheckBoxConversion: false,
          defaultCheckBoxCompliance: false,
          defaultCheckBoxGA4: false,
          defaultCheckBoxShopifyOpt: false,
          defaultCheckBoxOther: false,
          defaultCheckEmailMarketing: false,
          defaultCheckBoxWordpress: false,
          is_processing: false,
        })
  
        fetch(AUTO_RESPONDER_CONTACT_FORM + "contact_us_auto_responder.php", {
          method: "post",
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          .then(data => {
            if (data.status_code == 201) {
              navigate("/thankyou");
            } else {
              _this.changeCaptcha();
              _this.setState({ submit_text: "Submit" });
            }
          })
          .catch((err) => {
            console.log(err)
            _this.changeCaptcha();
            _this.setState({ submit_text: "Submit" });
          });        

      } else {
        _this.changeCaptcha()
        _this.setState({ submit_text: "Submit" })
      }

    }).catch((error) => {
      _this.changeCaptcha()
      _this.setState({ submit_text: "Submit" })
    })
  }


  scrollTo = (position = 50) => window.scroll({
    top: this.serviceErrorDiv.current.offsetTop - position,
    left: 0,
    behavior: "smooth",
  })

  render() {
    return <Layout>
      <Helmet>
        <title> Contact Us | Get in Touch with CueForGood </title>
        <link rel="canonical" href={this.state.url} />
        <meta name="description"
              content="Passionate about making a difference? We are too! Fill out this form to Connect with us & start our journey together." />
        <meta property="og:site_name" content="CueForGood" />
        <meta property="fb:app_id" content="289086684439915" />
        <meta property="og:url" content={this.state.url} />
        <meta property="og:title" content="Contact Us | Get in Touch with CueForGood" />
        <meta property="og:description"
              content="Passionate about making a difference? We are too! Fill out this form to Connect with us & start our journey together." />
        <meta property="og:image" content="https://www.cueforgood.com/images/cb.jpg" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description"
              content="Passionate about making a difference? We are too! Fill out this form to Connect with us & start our journey together." />
        <meta name="twitter:title" content="Contact Us | Get in Touch with CueForGood" />
        <meta name="twitter:site" content="@CueForGood" />
        <meta name="twitter:image" content="https://www.cueforgood.com/images/cb.jpg" />
      </Helmet>


      <section className="contact-form__block contact-newdesign page-header">
        <div className="container">
          <div className="contact-left">
            <div className="c-service__lt">
              <h2 className="heading_main">Let's start the conversation!</h2>
              <h4>You'll be hearing from either Ravi or Pancham once we receive more details.</h4>
              <div className="cb_members">
                <ul>
                  <li>
                    <a href="https://www.linkedin.com/in/ravi-bhushan-sharma-612b5534  " target="_blank"
                       rel="noreferrer">
                      <img src={Ravi} alt="Ravi" className="img-responsive" />
                      <img src={Linkedin_icon} alt="Ravi Linkedin profile" className="linkedin-icon" />
                    </a>

                    <p>Ravi Sharma</p>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/pancham/?originalSubdomain=in " target="_blank"
                       rel="noreferrer">
                      <img src={Pancham} alt="Pancham" className="img-responsive" />
                      <img src={Linkedin_icon} alt="Pancham Linkedin profile" className="linkedin-icon" />
                    </a>
                    <p>Pancham Prashar</p>
                  </li>
                </ul>
              </div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="contact-form__row">
                <div className="form__lt">
                  <h4 className="heading_main">Please fill the following form or send us an email at
                    <a  oncopy="copiedEmaill()" href="mailto:letstalk@cueforgood.com" className="normal-link letstalkemail"> letstalk@cueforgood.com</a>.
                    <span className="copy-span">
                      <button className="letstalkemail copy-btn" type="button" onClick={this.copyToClipboard}>
                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.39988 2.4V0.6C2.39988 0.268632 2.66851 0 2.99988 0H10.1999C10.5313 0 10.7999 0.268632 10.7999 0.6V9C10.7999 9.33138 10.5313 9.6 10.1999 9.6H8.39988V11.3995C8.39988 11.7311 8.12994 12 7.7958 12H0.603996C0.270354 12 0 11.7332 0 11.3995L0.00155997 3.00052C0.00161997 2.66887 0.271584 2.4 0.605652 2.4H2.39988ZM1.20145 3.6L1.20011 10.8H7.19988V3.6H1.20145ZM3.59988 2.4H8.39988V8.4H9.59988V1.2H3.59988V2.4Z" fill="#676767"/>
                      </svg>
                       Copy Email</button> 
                      <span className="copy-tooltip">{this.state.copySuccess}</span>
                    </span>
                    </h4>
                    
                  <h6>(<em>*</em> indicates mandatory fields)</h6>
                  <div className="form__rt">

                    <div className="form__row field-group">
                      <label className={this.state.fieldActivate ? "field-active" : ""}>Name<em>*</em> </label>
                      <input
                        id="name"
                        className="floating-label"
                        onFocus={this.activateField}
                        onBlur={this.disableFocus}
                        type="text"
                        name="name"
                        value={this.state.name}
                        // value={this.state.inputValue}
                        // placeholder="Name*"
                        onChange={this.handleInputChange}
                        // onChange={this.updateInputValue}
                      />
                      {this.state.hasNameError ?
                        <h6 className="error-msgg">{this.state.nameErrorMessage}</h6>
                        : null}

                    </div>
                    <div className="form__row field-group">
                      <label className={this.state.fieldActivate1 ? "field-active" : ""}>Email<em>*</em> </label>
                      <input
                        id="email"
                        type="text"
                        // pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        title="hello@domain.com"
                        name="email"
                        value={this.state.email}
                        // placeholder="Email*"
                        onChange={this.handleInputChange}
                        className="floating-label"
                        onFocus={this.activateField1}
                        onBlur={this.disableFocus1}
                      />
                      {this.state.hasEmailError ?
                        <h6 className="error-msgg">{this.state.nameEmailMessage}</h6>
                        : null}

                    </div>
                    <div className="form__row field-group">
                      <label className={this.state.fieldActivate2 ? "field-active" : ""}>Phone <span>(optional)</span>
                      </label>
                      <input
                        id="phone"
                        type="text"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleInputChange}
                        className="floating-label"
                        onFocus={this.activateField2}
                        onBlur={this.disableFocus2}
                      />
                    </div>
                    <div className="form__row field-group">
                      <label className={this.state.fieldActivate3 ? "field-active" : ""}>Website <span>(optional)</span>
                      </label>
                      <input
                        id="website"
                        type="text"
                        title="example.com"
                        name="website"
                        value={this.state.website}
                        // placeholder="Website (optional)"
                        onChange={this.handleInputChange}
                        className="floating-label"
                        onFocus={this.activateField3}
                        onBlur={this.disableFocus3}
                      />
                    </div>

                    <div className="select-serrvice" ref={this.serviceErrorDiv}>
                      <h4>Services that interest you<em>*</em></h4>
                      {this.state.hasServiceError ?
                        <h6 className="error-msgg">{this.state.serviceErrorMessage}</h6>
                        : null}
                      <div className="checkbox__row">
                        <input id="Magento" type="checkbox" checked={this.state.defaultCheckBoxMagento}
                               onChange={this.handleCheckbox} />
                        <label htmlFor="Magento">
                          <span></span>
                          Magento
                        </label>
                      </div>
                      <div className="checkbox__row">
                        <input id="Shopify" type="checkbox" checked={this.state.defaultCheckBoxShopify}
                               onChange={this.handleCheckbox} />
                        <label htmlFor="Shopify">
                          <span></span>
                          Shopify
                        </label>
                      </div>
                      <div className="checkbox__row">
                        <input id="WebDevelopment" type="checkbox" checked={this.state.defaultCheckBoxWeb}
                               onChange={this.handleCheckbox} />
                        <label htmlFor="WebDevelopment">
                          <span></span>
                          Web Development
                        </label>
                      </div>
                      <div className="checkbox__row">
                        <input id="CreativeDesign" type="checkbox" checked={this.state.defaultCheckBoxDesign}
                               onChange={this.handleCheckbox} />
                        <label htmlFor="CreativeDesign">
                          <span></span>
                          Creative Design
                        </label>
                      </div>
                      <div className="checkbox__row">
                        <input id="OrganicSearch" type="checkbox" checked={this.state.defaultCheckBoxSearch}
                               onChange={this.handleCheckbox} />
                        <label htmlFor="OrganicSearch">
                          <span></span>
                          Organic Search
                        </label>
                      </div>
                      <div className="checkbox__row">
                        <input id="DigitalAdvertising" type="checkbox" checked={this.state.defaultCheckBoxAd}
                               onChange={this.handleCheckbox} />
                        <label htmlFor="DigitalAdvertising">
                          <span></span>
                          Digital Advertising
                        </label>
                      </div>

                      <div className="checkbox__row">
                        <input id="Wordpress" type="checkbox" checked={this.state.defaultCheckBoxWordpress}
                               onChange={this.handleCheckbox} />
                        <label htmlFor="Wordpress">
                          <span></span>
                          Wordpress
                        </label>
                      </div>
                      <div className="checkbox__row">
                        <input id="EmailMarketing" type="checkbox" checked={this.state.defaultCheckBoxEmailMarketing}
                               onChange={this.handleCheckbox} />
                        <label htmlFor="EmailMarketing">
                          <span></span>
                          Email Marketing
                        </label>
                      </div>
                      <div className="checkbox__row">
                        <input id="Conversion" type="checkbox" checked={this.state.defaultCheckBoxConversion}
                               onChange={this.handleCheckbox} />
                        <label htmlFor="Conversion">
                          <span></span>
                          Conversion
                        </label>
                      </div>
                      <div className="checkbox__row">
                            <input id="ADA/WCAGCompliance" type="checkbox" checked={this.state.defaultCheckBoxCompliance}
                                  onChange={this.handleCheckbox} />
                            <label htmlFor="ADA/WCAGCompliance">
                                  <span></span>
                                  ADA/WCAG Compliance
                            </label>
                      </div>
                      <div className="checkbox__row">
                            <input id="GoogleAnalytics4(GA4)Transition" type="checkbox" checked={this.state.defaultCheckBoxGA4} onChange={this.handleCheckbox} />
                            <label htmlFor="GoogleAnalytics4(GA4)Transition">
                                  <span></span>
                                  Google Analytics 4 (GA4) Transition
                            </label>
                      </div>
                      <div className="checkbox__row">
                            <input id="ShopifySpeedOptimization" type="checkbox" checked={this.state.defaultCheckBoxShopifyOpt}
                                  onChange={this.handleCheckbox} />
                            <label htmlFor="ShopifySpeedOptimization">
                                  <span></span>
                                  Shopify Speed Optimization
                            </label>
                      </div>
                      <div className="checkbox__row">
                        <input id="Other" type="checkbox" checked={this.state.defaultCheckBoxOther}
                               onChange={this.handleCheckbox} />
                        <label htmlFor="Other">
                          <span></span>
                          Other
                        </label>
                      </div>
                    </div>

                    <div className="form__block2_lt form__row field-group textarea-field">
                      <label className={this.state.fieldActivate4 ? "field-active" : ""}>Please share details of your
                        story & project requirements<em>*</em></label>
                      <textarea name="about"
                        // placeholder="Please share details of your story & project requirements*"
                                value={this.state.about}
                                onChange={this.handleInputChange}
                                onFocus={this.activateField4}
                                onBlur={this.disableFocus4}
                      >

                                      </textarea>
                      {this.state.hasAboutError ?
                        <h6 className="error-msgg">{this.state.aboutErrorMessage}</h6>
                        : null}
                    </div>

                    <div className="form__row field-group">
                      <label className={this.state.fieldActivate5 ? "field-active" : ""}>How did you hear about
                        CueForGood? <span>(optional)</span> </label>
                      <input
                        id="hearabout"
                        type="text"
                        name="hearabout"
                        value={this.state.hearabout}
                        // placeholder="How did you hear about CueForGood? (optional)"
                        onChange={this.handleInputChange}
                        onFocus={this.activateField5}
                        onBlur={this.disableFocus5}
                      />


                    </div>

                    <div className="form__row captcha-row" key={this.state.key}>
                      <Captcha {...this.state} /><em>*</em> <span className="chnage-captcha"
                                                                  onClick={this.changeCaptcha}> Change</span>

                      <input type="text"
                             name="captcha"
                             id="captcha"
                             autoComplete={"off"}
                             placeholder="Enter Captcha here"
                             value={this.state.captcha}
                             onChange={this.handleInputChange} />


                    </div>
                    {this.state.hasCaptchaError ?
                      <h6 className="error-msgg">{this.state.captchaErrorMessage}</h6>
                      : null}

                    <input type="submit" className="main_cta" value={this.state.submit_text}
                           disabled={this.state.is_processing} />
                    {this.state.displayResult ?
                      <div className={this.state.displayClass} style={{ textAlign: "center", marginTop: "10px" }}
                           dangerouslySetInnerHTML={{ __html: this.state.response }}></div> : ""
                    }

                  </div>

                  {/* <div className="contact-budget__row">
                                        <div className="budget__block">
                                            <h3 className="heading_main">Budget</h3>
                                            <p>
                                                An upfront Budget estimate will help us ensure expectations are met.
                                                Ballparks are fine.
                                        </p>
                                            <div className="selecteurPrix">
                                                <div className="label__g label__m">
                                                    <span className="range-value">${this.state.budget}</span>
                                                </div>
                                                <div className="range-slider">
                                                    <input
                                                        className="input-range"
                                                        type="range"
                                                        defaultValue={this.state.budget}
                                                        onChange={(e) => {
                                                            this.setState({ budget: e.target.value })
                                                        }}
                                                        min="3000"
                                                        max="50000"
                                                    />
                                                </div>
                                                <div className="PrixMin PrixRange">$3K</div>
                                                <div className="PrixMax PrixRange">$50k+</div>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </form>
          </div>
          <div className="contact-right">
            <h2 className="heading_main">We work from</h2>
            <div className="address">
              <div className="address-inn">
                <div className="add-img">
                  <img src={Clock_Chd} alt="Clock Chandigarh" className="img-responsive" />
                  <span>S.A.S Nagar, IN</span>
                </div>
                <p className="address-cb">Plot 1075 A, Third Floor,<br />
                  Sector 82, JLPL Industrial Area, <br />
                  Sahibzada Ajit Singh (S.A.S) Nagar, Punjab - 160055 <br />
                  India <br /><br />
                  Careers: <a href="tel:+91–987-6008994">+91–987-6008994</a>
                  </p>
              </div>
          
            </div>
            
          </div>
        </div>
      </section>
    </Layout>
  }
}
